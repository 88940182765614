import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import isNumber from '../../helpers/numbers';
import { cleanContactType } from '../../helpers';
import { CONTACT_TYPES } from '../../constants/contact-types';
import useUserHasPerm from '../../hooks/useUserHasPerm';
import { useSnackBars } from '../../hooks';
import FormDataGrid from '../dataGrids/FormDataGrid';
import { ArrayOfId, MasterEntityType } from '../../propTypes';
import { ADD_OR_EDIT_CONTACT, CONTACTS, ERASE_CONTACT } from '../../graphql';
import { CONTACT_COLUMNS, contactTypeToText } from '../../constants/contact-columns';

const ContactsFormGrid = ({
  masterEntity,
  refetchDebtor,
  contactType,
  showAllContactTypes,
  setSelectedContact,
  selectedContacts,
  checkbox,
  columns,
}) => {
  const [contacts, setContacts] = useState([]);
  const { addAlert } = useSnackBars();
  const masterEntityId = masterEntity.id;
  const setValidSelectedContactIds = useCallback(
    (contactIds) => {
      setSelectedContact(contactIds.filter((id) => isNumber(id)));
    },
    [setSelectedContact],
  );
  const {
    loading,
    refetch,
    data: companyContactData,
  } = useQuery(CONTACTS, {
    variables: {
      masterEntity_In: [masterEntityId],
      showHidden: false,
      ...(showAllContactTypes ? {} : { contactType }),
    },
    skip: !masterEntityId,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const contactsData = Object.values(data)[0].edges.map((edge) => edge.node);
      setContacts(contactsData);
    },
  });
  const originalContacts = companyContactData
    ? Object.values(companyContactData)[0].edges.map((edge) => edge.node)
    : [];
  const onSubmit = () => {
    refetch();
    refetchDebtor();
  };

  const onError = (err) => {
    addAlert({
      id: 0,
      color: 'error',
      severity: 'error',
      message: err.message,
    });
  };

  const [createContact] = useMutation(ADD_OR_EDIT_CONTACT, {
    onCompleted: (data) => {
      const createContactResponse = data.createOrEditContact;
      addAlert({
        id: 0,
        color: 'success',
        severity: 'success',
        message: createContactResponse.message,
      });
      onSubmit();
    },
    onError,
  });
  const [updateContact] = useMutation(ADD_OR_EDIT_CONTACT, {
    onCompleted: () => {
      addAlert({
        id: 0,
        color: 'success',
        severity: 'success',
        message: 'Contactos actualizado',
      });
      onSubmit();
    },
    onError,
  });
  const [deleteContact] = useMutation(ERASE_CONTACT, {
    onCompleted: () => {
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Contactos eliminados',
      });
      onSubmit();
    },
    onError,
  });

  const onCreateNewContact = useCallback(
    (row) => {
      const hasSourceColumn = columns.some(
        (column) => column.field === 'source',
      );
      createContact({
        variables: {
          isNew: true,
          company: masterEntityId,
          name: row.name,
          position: row.position,
          phoneNumber: row.phoneNumber,
          email: row.email,
          source: hasSourceColumn ? row.source : 'PROVIDED_BY_CLIENT',
          contactType: row.contactType ? row.contactType : contactType,
        },
      });
    },
    [createContact],
  );

  const onUpdateContact = useCallback(
    (row) => {
      const updatedContactType = cleanContactType(row.contactType);
      updateContact({
        variables: {
          id: row.id,
          isNew: false,
          company: masterEntityId,
          name: row.name,
          position: row.position,
          phoneNumber: row.phoneNumber,
          email: row.email,
          source: row.source,
          contactType: updatedContactType || contactType,
        },
      });
    },
    [updateContact],
  );

  const onDeleteContact = useCallback(
    (id) => {
      deleteContact({
        variables: {
          id,
          company: masterEntityId,
          contactType,
        },
      });
    },
    [deleteContact],
  );

  const shouldHideFooter = useMemo(() => contacts.length < 100, [contacts]);

  const userHasKeyPerm = useUserHasPerm('auth.assign_contact_type_commercial_key');

  let newColumns = columns;
  newColumns = [
    ...newColumns,
    {
      field: 'contactType',
      headerName: 'Contacto clave',
      type: 'singleSelect',
      minWidth: 120,
      align: 'center',
      valueOptions: ['PREOFFER_COMMERCIAL_KEY', 'COMMERCIAL'],
      renderCell: ({ row }) => {
        const contactTypes = Array.isArray(row.contactType) ? row.contactType : [];
        const isKeyContact = contactTypes.some(
          (ct) => ct.code === CONTACT_TYPES.PREOFFER_COMMERCIAL_KEY,
        );
        return <Typography>{isKeyContact ? 'Sí' : ''}</Typography>;
      },
      valueFormatter: ({ value }) => contactTypeToText[value],
      editable: true,
      flex: 1,
      hide: !userHasKeyPerm,
    },
  ];

  return (
    <FormDataGrid
      rows={contacts}
      setRows={setContacts}
      columns={newColumns}
      loadingWithSkeleton={loading}
      boxProps={{
        sx: { height: shouldHideFooter ? 300 : 420, width: '100%' },
      }}
      hideFooter={shouldHideFooter}
      originalRows={originalContacts}
      onCreate={onCreateNewContact}
      onUpdate={onUpdateContact}
      onDelete={onDeleteContact}
      checkboxSelection={checkbox}
      onSelectionModelChange={setValidSelectedContactIds}
      selectionModel={selectedContacts}
    />
  );
};

ContactsFormGrid.propTypes = {
  masterEntity: MasterEntityType.isRequired,
  refetchDebtor: PropTypes.func,
  contactType: PropTypes.string.isRequired,
  showAllContactTypes: PropTypes.bool.isRequired,
  setSelectedContact: PropTypes.func,
  selectedContacts: ArrayOfId,
  checkbox: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
};

ContactsFormGrid.defaultProps = {
  refetchDebtor: () => null,
  setSelectedContact: () => {},
  selectedContacts: [],
  checkbox: false,
  columns: CONTACT_COLUMNS,
};

export default ContactsFormGrid;
