import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { Stack, CircularProgress } from '@mui/material';
import FingoDialog from '../dialogs/FingoDialog';
import { GET_COMPANY_CONTRACT_GENERATION, GENERATE_COMPANY_FRAMEWORK_CONTRACT } from '../../graphql';
import { useSnackBars } from '../../hooks';
import useFetchPresignedUrl from '../../hooks/useFetchPresignedUrl';
import useLegalRepresentatives from '../../hooks/useLegalRepresentatives';
import useEndorsements from '../../hooks/useEndorsements';
import LegalRepresentativesComponent from './LegalRepresentativesComponent';
import ContractInfoComponent from './ContractInfoComponent';
import EndorsementComponent from './EndorsementComponent';
import ContinueButtonComponent from './ContinueButtonComponent';
import HandleResponse from './HandleResponse';
import ContractRestriction from './ContractRestriction';
import LegalReportPowers from './LegalReportPowers';
import PersoneriaCommentsComponent from './PersoneriaCommentsComponent';

const GenerateContractDialog = ({ open, toggleDialog, companyId }) => {
  const { addAlert } = useSnackBars();
  const [contractInfo, setContractInfo] = useState({});
  const [relatedContractRequest, setRelatedContractRequest] = useState({});
  const [availableEndorsements, setAvailableEndorsements] = useState([]);
  const [availableRepresentatives, setAvailableRepresentatives] = useState([]);
  const [legalReport, setLegalReport] = useState({});
  const [personeriaComments, setPersoneriaComments] = useState('');

  const { legalRepresentatives,
    addLegalRepresentative,
    removeLegalRepresentative,
    updateLegalRepresentative,

  } = useLegalRepresentatives();
  const { endorsements, addEndorsement, removeEndorsement, updateEndorsement } = useEndorsements(
    availableEndorsements,
    relatedContractRequest?.restriction?.endorser?.relatedPerson.displayNationalIdentifier,
  );
  const [,, fetchGivenId] = useFetchPresignedUrl();

  const openUrl = (globalAppId) => fetchGivenId(globalAppId);

  const { loading } = useQuery(GET_COMPANY_CONTRACT_GENERATION, {
    variables: { companyId },
    notifyOnNetworkStatusChange: true,
    skip: !open,
    onCompleted: (data) => {
      HandleResponse(
        data.company,
        setContractInfo,
        setAvailableRepresentatives,
        setAvailableEndorsements,
        setRelatedContractRequest,
        setLegalReport,
        setPersoneriaComments,
      );
    },
  });

  const finalContractInfo = useMemo(() => {
    const { mainDisabled, ...rest } = contractInfo;
    return rest;
  }, [contractInfo]);

  const neededLegalRepresentativesProps = useMemo(() => [
    'name',
    'rut',
    'nationality',
    'profession',
    'email',
    'civilStatus',
    'label',
  ], []);

  const filteredPropsLegalRepresentatives = useMemo(() => legalRepresentatives.map((rep) => {
    const filteredRep = Object.keys(rep).reduce(
      (acc, key) => {
        if (neededLegalRepresentativesProps.includes(key)) {
          acc[key] = rep[key];
        }
        return acc;
      },
      {},
    );
    return filteredRep;
  }), [legalRepresentatives, neededLegalRepresentativesProps]);

  const neededEndorsementsProps = useMemo(() => [
    'name',
    'rut',
    'nationality',
    'civilStatus',
    'profession',
    'address',
    'email',
    'label',
    'spouceName',
    'spouceRut',
    'spouceAddress',
    'spouceLabel',
    'spouceNationality',
    'spouceMasterEntityId',
    'masterEntityId',
  ], []);

  const personeriaCommentsValue = personeriaComments.personeriaComments || '';

  const filteredPropsEndorsements = useMemo(() => endorsements.map((endorsement) => {
    const filteredEndorsement = Object.keys(endorsement).reduce(
      (acc, key) => {
        if (neededEndorsementsProps.includes(key)) {
          acc[key] = endorsement[key];
        }
        return acc;
      },
      {},
    );
    return filteredEndorsement;
  }), [endorsements, neededEndorsementsProps]);

  const [generateCompanyFrameworkContract, { loading: generateLoading }] = useMutation(
    GENERATE_COMPANY_FRAMEWORK_CONTRACT,
    {
      variables: {
        companyId,
        contractInfo: finalContractInfo,
        legalRepresentatives: filteredPropsLegalRepresentatives,
        endorsements: filteredPropsEndorsements,
        personeriaComments: personeriaCommentsValue,

      },
      onCompleted: (data) => {
        openUrl(data.generateCompanyFrameworkContract.frameworkContract.globalAppId);
      },
      onError: (error) => addAlert({
        id: 'generate-contract-error',
        severity: 'error',
        message: `Error: ${error.message}`,
      }),
    },
  );

  return (
    <FingoDialog
      maxWidth="lg"
      open={open && !loading}
      handleClose={toggleDialog}
      title="Generación de Contrato"
      dialogActionButton={(
        <ContinueButtonComponent
          contractInfo={contractInfo}
          legalRepresentatives={legalRepresentatives}
          endorsements={endorsements}
          generateCompanyFrameworkContract={generateCompanyFrameworkContract}
          generateLoading={generateLoading}
        />
      )}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={3} paddingX={1.5}>
            {relatedContractRequest?.restriction && (
              <ContractRestriction contractRestriction={relatedContractRequest.restriction} />
            )}
            {legalReport?.signatureCount && (
              <LegalReportPowers
                creditOperationsAvailable={legalReport?.creditOperationsAvailable}
                factoringAvailable={legalReport?.factoringAvailable}
                signatureCount={legalReport?.signatureCount}
              />
            )}
          </Stack>

          <Stack direction="column" spacing={2}>
            <ContractInfoComponent contractInfo={contractInfo} setContractInfo={setContractInfo} />
            <LegalRepresentativesComponent
              legalRepresentatives={legalRepresentatives}
              addLegalRepresentative={addLegalRepresentative}
              removeLegalRepresentative={removeLegalRepresentative}
              updateLegalRepresentative={updateLegalRepresentative}
              availableRepresentatives={availableRepresentatives}
            />
            <PersoneriaCommentsComponent
              personeriaComments={personeriaComments}
              setPersoneriaComments={setPersoneriaComments}
            />
            <EndorsementComponent
              endorsements={endorsements}
              addEndorsement={addEndorsement}
              removeEndorsement={removeEndorsement}
              updateEndorsement={updateEndorsement}
              availableEndorsements={availableEndorsements}
              mandatoryEndorsementRut={relatedContractRequest?.restriction?.endorser
                ?.relatedPerson.displayNationalIdentifier}
            />
          </Stack>
        </Stack>
      )}
    </FingoDialog>
  );
};

GenerateContractDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default GenerateContractDialog;
