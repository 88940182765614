import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const ContractTextInput = ({ input, obj, handleInfoFunc }) => (
  <TextField
    sx={input.sx}
    id={input.id}
    label={input.label}
    value={obj[input.id]}
    variant="outlined"
    onChange={(e) => handleInfoFunc(e.target.id, e.target.value, obj.id)}
    disabled={input.disabled && obj.mainDisabled}
    InputLabelProps={{ shrink: true }}
    inputProps={input.inputProps}
    minRows={input.minRows ?? 1}
    multiline={input.multiline ?? false}
  />
);

ContractTextInput.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    minRows: PropTypes.number,
    multiline: PropTypes.bool,
    sx: PropTypes.shape({
      width: PropTypes.string,
    }),
    inputProps: PropTypes.shape({
      style: PropTypes.shape({
        textTransform: PropTypes.string,
      }),
    }),
  }).isRequired,
  obj: PropTypes.shape({
    id: PropTypes.number,
    mainDisabled: PropTypes.bool,
  }).isRequired,
  handleInfoFunc: PropTypes.func.isRequired,
};

export default ContractTextInput;
