import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

const TitleComponent = ({ number, label, children }) => (
  <Stack direction="row" spacing={1} sx={{ marginLeft: 2 }} alignItems="center">
    <Avatar sx={{ height: 30, width: 30, bgcolor: '#838383', fontSize: 12 }}>{number}</Avatar>
    <Typography variant="h6" sx={{ paddingTop: 0.8 }}>
      {label}
    </Typography>
    {children}
  </Stack>
);

TitleComponent.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string,
  children: PropTypes.node,
};

TitleComponent.defaultProps = {
  children: null,
  label: '',
};

export default TitleComponent;
