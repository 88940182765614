import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Upload from '@mui/icons-material/Upload';
import { useBooleanState } from '../../hooks';
import GenerateContractDialog from './GenerateContractDialog';

const GenerateContractChip = ({ document, companyId }) => {
  const [open, toggleDialog] = useBooleanState(false);

  return (
    <>
      <Tooltip title="Generar Contrato">
        <Chip
          size="small"
          label={document.label}
          onClick={toggleDialog}
          id={document.documentType.toLowerCase()}
          color="primary"
          variant="filled"
          icon={open ? (
            <CircularProgress size={12} />
          ) : (
            <Upload />
          )}
          sx={{ '& .MuiChip-label': { fontSize: 11 }, marginInline: 0.2, marginTop: 0.5 }}
        />
      </Tooltip>
      <GenerateContractDialog
        open={open}
        toggleDialog={toggleDialog}
        companyId={companyId}
      />
    </>
  );
};

GenerateContractChip.propTypes = {
  companyId: PropTypes.string.isRequired,
  document: PropTypes.shape({
    label: PropTypes.string,
    documentType: PropTypes.string,
  }).isRequired,
};

export default GenerateContractChip;
