import React from 'react';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { personeriaCommentsInput } from './constants/contract-chip-inputs';

const PersoneriaCommentsComponent = ({ personeriaComments, setPersoneriaComments }) => {
  const handleInfoFunc = (eventId, eventValue) => {
    setPersoneriaComments({
      personeriaComments: eventValue,
    });
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          px: 2,
        }}
      >
        <FormControl variant="outlined" sx={{ width: '100%' }}>
          {personeriaCommentsInput.map((rowsInput) => (
            <Stack
              direction="row"
              spacing={2}
              sx={{ width: '100%' }}
              key="personeria-row"
            >
              {rowsInput.map((input) => (
                <input.component
                  key={`personeria-${input.id}`}
                  input={input}
                  obj={personeriaComments}
                  handleInfoFunc={handleInfoFunc}
                />
              ))}
            </Stack>
          ))}
        </FormControl>

      </Stack>
    </>
  );
};

PersoneriaCommentsComponent.propTypes = {
  personeriaComments: PropTypes.shape({
    personeriaComments: PropTypes.string,
  }).isRequired,
  setPersoneriaComments: PropTypes.func.isRequired,
};

export default PersoneriaCommentsComponent;
