import React from 'react';
import { Stack, Typography, Card, CardContent, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { CheckCircle, Cancel } from '@mui/icons-material';

const LegalReportPowers = ({ creditOperationsAvailable, factoringAvailable, signatureCount }) => (
  <Card
    variant="outlined"
    sx={{
      my: 2,
      padding: 2,
      backgroundColor: '#f9f9f9',
      borderRadius: 2,
    }}
  >
    <CardContent>
      <Typography variant="h5" gutterBottom>
        Requisitos del contrato
      </Typography>

      <Stack direction="row" spacing={1} alignItems="center" mt={2}>
        {creditOperationsAvailable ? (
          <CheckCircle color="success" />
        ) : (
          <Cancel color="error" />
        )}
        <Typography variant="body1">
          {creditOperationsAvailable ? 'Operaciones de crédito' : 'Sin operaciones de crédito'}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center" mt={2}>
        {factoringAvailable ? (
          <CheckCircle color="success" />
        ) : (
          <Cancel color="error" />
        )}
        <Typography variant="body1">
          {factoringAvailable ? 'Factoring disponible' : 'Sin factoring'}
        </Typography>
      </Stack>

      <Box mt={2}>
        <Typography variant="body1">
          <Box
            component="span"
            sx={{
              fontWeight: 'bold',
              fontSize: 15,
              color: 'primary.main',
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: 1.5,
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 10,
              height: 10,
              marginRight: 1,
              boxShadow: 3,
            }}
          >
            {signatureCount}
          </Box>
          {
            signatureCount === 1
              ? ' Firma necesaria'
              : ' Firmas necesarias'
          }
        </Typography>
      </Box>

    </CardContent>
  </Card>
);

LegalReportPowers.propTypes = {
  creditOperationsAvailable: PropTypes.bool.isRequired,
  factoringAvailable: PropTypes.bool.isRequired,
  signatureCount: PropTypes.number.isRequired,
};

export default LegalReportPowers;
