const setContractRequest = (company, setFunc) => {
  if (company.pendingFrameworkContractRequest) {
    setFunc(company.pendingFrameworkContractRequest);
  }
};

const getLegalRepresentatives = (company) => {
  if (company.legalReport) {
    return company.legalReport[0].managementPeople;
  }
  return company.legalRepresentative || [];
};

const setMultipleLegalRepresentatives = (company, setFunc) => {
  const lrArray = getLegalRepresentatives(company);
  setFunc(lrArray.map((end, index) => (
    {
      id: index,
      masterEntityId: end.relatedPerson.id,
      label: end.label || 'SR',
      name: end.relatedPerson.name,
      rut: end.relatedPerson.displayNationalIdentifier,
      email: end.email?.toLowerCase() || '',
      nationality: end.nationality?.toLowerCase() || 'chileno',
      profession: end.profession?.toLowerCase() || '',
      civilStatus: end.civilStatus?.toLowerCase() || 'single',
      mainDisabled: true,
    })));
};

const setMultipleEndorsements = (company, setFunc) => {
  const endArray = company.endorsements || [];
  setFunc(endArray.map((rep, index) => (
    {
      id: index,
      masterEntityId: rep.relatedPerson.id,
      label: rep.label || 'SR',
      name: rep.relatedPerson.name,
      rut: rep.relatedPerson.displayNationalIdentifier,
      nationality: rep.nationality?.toLowerCase() || 'chileno',
      profession: rep.profession?.toLowerCase() || '',
      civilStatus: rep.civilStatus?.toLowerCase() || 'single',
      address: rep.address?.toLowerCase() || '',
      email: rep.email?.toLowerCase() || '',
      mainDisabled: true,
      spouceName: rep.relatedSpouce?.name || '',
      spouceLabel: rep.spouceLabel || 'SRA',
      spouceMasterEntityId: rep.relatedSpouce?.id || null,
      spouceRut: rep.relatedSpouce?.displayNationalIdentifier || '',
      spouceAddress: rep.spouceAddress?.toLowerCase() || '',
      spouceNationality: rep.spouceNationality?.toLowerCase() || '',
    })));
};

const setNaturalPersonLegalRepresentative = (company, setFunc) => {
  setFunc([{
    id: 0,
    masterEntityId: company.masterEntity.id,
    label: 'SR',
    name: company.name,
    rut: company.masterEntity.displayNationalIdentifier,
    email: '',
    nationality: 'chileno',
    profession: '',
    civilStatus: 'single',
    mainDisabled: true,
  }]);
};

const setNaturalPersonEndorsement = (company, setFunc) => {
  setFunc([{
    id: 0,
    masterEntityId: company.masterEntity.id,
    label: 'SR',
    name: company.name,
    rut: company.masterEntity.displayNationalIdentifier,
    nationality: 'chileno',
    profession: '',
    civilStatus: 'single',
    address: company.address?.toLowerCase() || '',
    email: '',
    mainDisabled: true,
    spouceName: '',
    spouceRut: '',
    spouceAddress: '',
    spouceNationality: '',
  }]);
};

const setCurrentLegalReport = (company, setFunc) => {
  if (company.legalReport) {
    setFunc(company.legalReport[0]);
  }
};

const setCurrentPersoneriaComments = (company, setFunc) => {
  if (company.legalReport) {
    setFunc({
      personeriaComments: company.legalReport[0].personeriaComments || '',
    });
  }
};

const setInfo = (company, setFunc) => {
  const legalReport = company?.legalReport ? company.legalReport[0] : null;
  const societyType = legalReport?.societyType || '';

  setFunc({
    mainActivity: company.mainActivity?.toLowerCase() || '',
    address: company.address?.toLowerCase() || '',
    rut: company.masterEntity.displayNationalIdentifier,
    societyType,
    name: company.name,
    mainDisabled: true,
  });
};

const HandleResponse = (
  company,
  setContractInfo,
  setLegalRepresentatives,
  setAvailableEndorsements,
  setRelatedContractRequest,
  setLegalReport,
  setPersoneriaComments,
) => {
  setInfo(company, setContractInfo);
  if (company.isNaturalPerson) {
    if (!company.legalRepresentative) {
      setNaturalPersonLegalRepresentative(company, setLegalRepresentatives);
    } else {
      setMultipleLegalRepresentatives(company, setLegalRepresentatives);
    }
    if (!company.endorsements) {
      setNaturalPersonEndorsement(company, setAvailableEndorsements);
    } else {
      setMultipleEndorsements(company, setAvailableEndorsements);
    }
  } else {
    setMultipleEndorsements(company, setAvailableEndorsements);
    setMultipleLegalRepresentatives(company, setLegalRepresentatives);
  }
  setContractRequest(company, setRelatedContractRequest);
  setCurrentLegalReport(company, setLegalReport);
  setCurrentPersoneriaComments(company, setPersoneriaComments);
};

export default HandleResponse;
