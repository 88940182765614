import React from 'react';
import PropTypes from 'prop-types';
import RutInput from '../../inputs/RutInput';

const ContractRutInput = ({ input, obj, handleInfoFunc }) => {
  const onChange = (e) => {
    handleInfoFunc(input.id, e.target.value, obj.id);
  };
  return (
    <RutInput
      id={input.id}
      initialIdentifierValue={obj[input.id]}
      onChange={onChange}
      disabled={input.disabled && obj.mainDisabled}
      InputLabelProps={{ shrink: true }}
      sx={input.sx}
      label={input.label}
      variant="outlined"
    />
  );
};

ContractRutInput.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    sx: PropTypes.shape({
      width: PropTypes.string,
    }),
    inputProps: PropTypes.shape({
      style: PropTypes.shape({
        textTransform: PropTypes.string,
      }),
    }),
  }).isRequired,
  obj: PropTypes.shape({
    id: PropTypes.number,
    rut: PropTypes.string,
    mainDisabled: PropTypes.bool,
  }).isRequired,
  handleInfoFunc: PropTypes.func.isRequired,
};

export default ContractRutInput;
