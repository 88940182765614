import React from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const PersonLabelInput = ({ input, obj, handleInfoFunc }) => (
  <FormControl sx={input.sx}>
    <InputLabel id="company-type-input-label">{input.label}</InputLabel>
    <Select
      id={input.id}
      label={input.label}
      onChange={(e) => handleInfoFunc(input.id, e.target.value, obj.id)}
      value={obj[input.id]}
    >
      <MenuItem value="SR">Sr.</MenuItem>
      <MenuItem value="SRA">Sra.</MenuItem>
      <MenuItem value="SRTA">Srta.</MenuItem>
    </Select>
  </FormControl>
);

PersonLabelInput.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    sx: PropTypes.shape({
      width: PropTypes.string,
    }),
    inputProps: PropTypes.shape({
      style: PropTypes.shape({
        textTransform: PropTypes.string,
      }),
    }),
  }).isRequired,
  obj: PropTypes.shape({
    id: PropTypes.number,
    rut: PropTypes.string,
    mainDisabled: PropTypes.bool,
  }).isRequired,
  handleInfoFunc: PropTypes.func.isRequired,
};

export default PersonLabelInput;
