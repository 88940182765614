import React from 'react';
import { useMutation } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { CREATE_CONTRACT_REQUEST, PENDING_CONTRACTS } from '../../graphql';
import { useSnackBars } from '../../hooks';

const CreateContractRequestButton = ({ companyId }) => {
  const [createContractRequest, { loading }] = useMutation(
    CREATE_CONTRACT_REQUEST,
    { refetchQueries: [PENDING_CONTRACTS] },
  );
  const { addAlert } = useSnackBars();

  const handleClick = () => {
    createContractRequest({
      variables: {
        companyId,
      },
      onCompleted: () => {
        addAlert({
          id: 'create-contract-request',
          message: 'Solicitud de contrato creada correctamente',
        });
      },

    });
  };

  return (
    <Tooltip title="Nueva solicitud de contrato">
      <LoadingButton
        variant="contained"
        onClick={handleClick}
        loading={loading}
        size="small"
      >
        Solicitar contrato
      </LoadingButton>
    </Tooltip>
  );
};

CreateContractRequestButton.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CreateContractRequestButton;
