import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { useBooleanState } from '../../hooks';
import GenerateContractDialog from './GenerateContractDialog';

const GenerateContractButton = ({ companyId, contractRequestStatus }) => {
  const [open, toggleDialog] = useBooleanState(false);

  const isDisabled = contractRequestStatus !== 'PENDINGGENERATION';
  const tooltipText = isDisabled ? 'Contrato no está listo para generarse' : '';

  return (
    <Box sx={{ mx: 4 }}>
      <Tooltip
        title={tooltipText}
        componentsProps={{
          tooltip: {
            sx: {
              zIndex: 9999,
            },
          },
        }}
      >
        <Typography component="span">
          <IconButton
            onClick={toggleDialog}
            size="small"
            disabled={isDisabled}
            sx={{
              color: 'primary.main',
              '&:disabled': {
                color: 'grey',
              },
            }}
          >
            <AddIcon />
          </IconButton>
        </Typography>
      </Tooltip>
      <GenerateContractDialog
        open={open}
        toggleDialog={toggleDialog}
        companyId={companyId}
      />
    </Box>
  );
};

GenerateContractButton.propTypes = {
  companyId: PropTypes.string.isRequired,
  contractRequestStatus: PropTypes.string.isRequired,
};

export default GenerateContractButton;
