import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import TitleComponent from './TitleComponent';
import { endorsementsInputs, endorsementsSpouseInputs } from './constants/contract-chip-inputs';

const EndorsementComponent = ({
  endorsements,
  addEndorsement,
  removeEndorsement,
  updateEndorsement,
  availableEndorsements = [],
}) => {
  const [selectedEndorsement, setSelectedEndorsement] = useState('');

  const handleAddEndorsement = () => {
    if (selectedEndorsement === 'empty') {
      addEndorsement({
        id: endorsements.length,
        label: 'SR',
        name: '',
        rut: '',
        nationality: 'chileno',
        profession: '',
        civilStatus: 'single',
        address: '',
        email: '',
        mainDisabled: false,
        spouceName: '',
        spouceLabel: 'SRA',
        spouceRut: '',
        spouceAddress: '',
        spouceNationality: '',
      });
    } else {
      const newEndorsement = availableEndorsements.find((e) => e.id === selectedEndorsement);
      if (newEndorsement) {
        addEndorsement(newEndorsement);
      }
    }
    setSelectedEndorsement('');
  };

  const handleEndorsementInfo = (eventId, eventValue, endorsementId) => {
    updateEndorsement(endorsementId, { [eventId]: eventValue });
  };

  return (
    <>
      <TitleComponent number="3" label="Avales/Fiadores:" />
      <Stack direction="row" spacing={2} alignItems="center" sx={{ px: 2 }}>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel>Seleccionar</InputLabel>
          <Select
            value={selectedEndorsement}
            onChange={(e) => setSelectedEndorsement(e.target.value)}
            label="Seleccionar"
          >
            <MenuItem value="empty">Agregar vacío</MenuItem>
            {availableEndorsements.filter((e) => !endorsements.some((end) => end.id
             === e.id)).map((e) => (
               <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <AddCircleIcon color="primary" onClick={handleAddEndorsement} sx={{ cursor: 'pointer' }} />
      </Stack>

      <Stack alignItems="center" spacing={2}>
        {endorsements.map((end, index) => (
          <Stack direction="column" key={end.id}>
            <TitleComponent number={index + 1}>
              <Divider width="75%" />
              <Tooltip title={`Eliminar Aval ${end.name}`}>
                <RemoveCircleIcon
                  color="primary"
                  onClick={() => removeEndorsement(end.id)}
                  sx={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </TitleComponent>
            <Stack alignItems="center" spacing={2} sx={{ marginTop: 2 }}>
              {endorsementsInputs.map((rowsInput) => (
                <Stack direction="row" spacing={2} sx={{ width: '100%' }} key={`endorsement-row-${rowsInput[0]?.index}`}>
                  {rowsInput.map((input) => (
                    <input.component
                      key={`endorsement-input-${input.id}-${end.id}`}
                      input={input}
                      obj={end}
                      handleInfoFunc={handleEndorsementInfo}
                    />
                  ))}
                </Stack>
              ))}
              {end.civilStatus === 'married_society' && endorsementsSpouseInputs.map((rowsInput) => (
                <Stack direction="row" spacing={2} sx={{ width: '100%' }} key={`endorsement-spouse-row-${rowsInput[0]?.index}`}>
                  {rowsInput.map((input) => (
                    <input.component
                      key={`endorsement-spouse-input-${input.id}-${end.id}`}
                      input={input}
                      obj={end}
                      handleInfoFunc={handleEndorsementInfo}
                    />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

EndorsementComponent.propTypes = {
  endorsements: PropTypes.arrayOf(PropTypes.shape).isRequired,
  addEndorsement: PropTypes.func.isRequired,
  removeEndorsement: PropTypes.func.isRequired,
  updateEndorsement: PropTypes.func.isRequired,
  availableEndorsements: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default EndorsementComponent;
