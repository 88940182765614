import React from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CivilStatusInput = ({ input, obj, handleInfoFunc }) => (
  <Select
    id={input.id}
    label={input.label}
    sx={input.sx}
    onChange={(e) => handleInfoFunc('civilStatus', e.target.value, obj.id)}
    value={obj[input.id]}
  >
    <MenuItem value="single">Soltero/Divorciado/Viudo</MenuItem>
    <MenuItem value="married_society">Casado: Sociedad Conyugal</MenuItem>
    <MenuItem value="married_nonsociety">Casado: Separación de Bienes</MenuItem>
  </Select>
);

CivilStatusInput.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    sx: PropTypes.shape({
      width: PropTypes.string,
    }),
    inputProps: PropTypes.shape({
      style: PropTypes.shape({
        textTransform: PropTypes.string,
      }),
    }),
  }).isRequired,
  obj: PropTypes.shape({
    id: PropTypes.number,
    rut: PropTypes.string,
    mainDisabled: PropTypes.bool,
  }).isRequired,
  handleInfoFunc: PropTypes.func.isRequired,
};

export default CivilStatusInput;
