import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { formatRut } from '../../helpers';

const ContractRestriction = ({ contractRestriction }) => {
  const { contractType, endorser, description } = contractRestriction;
  const hasDetail = contractType || (endorser?.relatedPerson?.name);

  return (
    <Card
      variant="outlined"
      sx={{
        mx: 2,
        my: 2,
        padding: 2,
        backgroundColor: '#f9f9f9',
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Restricción de riesgo
        </Typography>

        {hasDetail && (
          <>
            {contractType && (
              <Stack direction="row" spacing={1} alignItems="center" mt={2}>
                <CheckCircle color="success" />
                <Typography variant="body1">
                  Tipo de contrato: {contractType}
                </Typography>
              </Stack>
            )}

            {endorser?.relatedPerson?.name && (
              <Stack direction="row" spacing={1} alignItems="center" mt={2}>
                <CheckCircle color="success" />
                <Typography variant="body1">
                  Aval del contrato: {endorser.relatedPerson.name} |&nbsp;
                  {formatRut(endorser.relatedPerson.rut)}
                </Typography>
              </Stack>
            )}

          </>
        )}

        {description && (
          <Box mt={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              Descripción adicional:
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {description || 'Sin descripción'}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

ContractRestriction.propTypes = {
  contractRestriction: PropTypes.shape({
    contractType: PropTypes.string,
    endorser: PropTypes.shape({
      relatedPerson: PropTypes.shape({
        name: PropTypes.string,
        rut: PropTypes.string,
      }),
    }),
    triggeringInvoices: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            folio: PropTypes.string,
          }),
        }),
      ),
    }),
    description: PropTypes.string,
  }).isRequired,
};

export default ContractRestriction;
